/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 

// Increase table font size
table { font-size: 100%; }

.PageNavigation {
    font-size: 18px;
    display: block;
    width: auto;
    overflow: hidden;
  }
  
  .PageNavigation a {
    display: block;
    width: 50%;
    margin: 1em 0;
  }

  .PageNavigation .prev {
    float: left;
  }

  .PageNavigation .next {
    float: right;
  }
  